// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/Dan/Sites/tristen-palmer-studio/spurs/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bowl-hsbc-js": () => import("/Users/Dan/Sites/tristen-palmer-studio/spurs/src/pages/bowl-hsbc.js" /* webpackChunkName: "component---src-pages-bowl-hsbc-js" */),
  "component---src-pages-bowl-nfl-js": () => import("/Users/Dan/Sites/tristen-palmer-studio/spurs/src/pages/bowl-nfl.js" /* webpackChunkName: "component---src-pages-bowl-nfl-js" */),
  "component---src-pages-bowl-js": () => import("/Users/Dan/Sites/tristen-palmer-studio/spurs/src/pages/bowl.js" /* webpackChunkName: "component---src-pages-bowl-js" */),
  "component---src-pages-index-js": () => import("/Users/Dan/Sites/tristen-palmer-studio/spurs/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-iptv-hsbc-js": () => import("/Users/Dan/Sites/tristen-palmer-studio/spurs/src/pages/live-iptv-hsbc.js" /* webpackChunkName: "component---src-pages-live-iptv-hsbc-js" */),
  "component---src-pages-live-iptv-icc-js": () => import("/Users/Dan/Sites/tristen-palmer-studio/spurs/src/pages/live-iptv-icc.js" /* webpackChunkName: "component---src-pages-live-iptv-icc-js" */),
  "component---src-pages-live-iptv-js": () => import("/Users/Dan/Sites/tristen-palmer-studio/spurs/src/pages/live-iptv.js" /* webpackChunkName: "component---src-pages-live-iptv-js" */),
  "component---src-pages-te-media-cafe-js": () => import("/Users/Dan/Sites/tristen-palmer-studio/spurs/src/pages/te-media-cafe.js" /* webpackChunkName: "component---src-pages-te-media-cafe-js" */),
  "component---src-pages-ticker-tape-banquet-js": () => import("/Users/Dan/Sites/tristen-palmer-studio/spurs/src/pages/ticker-tape-banquet.js" /* webpackChunkName: "component---src-pages-ticker-tape-banquet-js" */),
  "component---src-pages-ticker-tape-vertical-js": () => import("/Users/Dan/Sites/tristen-palmer-studio/spurs/src/pages/ticker-tape-vertical.js" /* webpackChunkName: "component---src-pages-ticker-tape-vertical-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/Dan/Sites/tristen-palmer-studio/spurs/.cache/data.json")

